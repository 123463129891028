export default class SettingsDataService {


  getClearingHouses(id) {
    // return $http.get(`/demoget/edit/${id}`);
    return [
      {
        id: 94723,
        name: 'Specialty Restaurant Group',
        email: 'DanielRHamilton@zoho.com',
        phone: '(904) 335-2403',
        website: 'zoho.com',
        status: 'active'
      },
      {
        id: 94724,
        name: 'E-zhe Source',
        email: 'KathyJPacheco@outlook.com',
        phone: '(785) 712-6532',
        website: 'www.reeoutlook.com',
        status: 'inactive'
      },
      {
        id: 94725,
        name: 'Britches of Georgetown',
        email: 'PatriciaTSanders@gmail.com',
        phone: '(301) 580-7410',
        website: null,
        status: 'active'
      },
      {
        id: 94725,
        name: 'Cala Foods',
        email: 'RodgerJStruck@mail.com',
        phone: '(978) 444-4055',
        website: null,
        status: 'active'
      },
      {
        id: 94725,
        name: 'Electronic Geek',
        email: 'KathyJPacheco@outlook.com',
        phone: '(401) 715-3344',
        website: null,
        status: 'inactive',
      },
    ];
  }
  getEHRSystems(id) {
    // return $http.get(`/demoget/edit/${id}`);
    return [
      {
        id: 94723,
        name: 'Specialty Restaurant Group',
        description: 'This EHR was set up in January still active, contact person Robert',
      },
      {
        id: 94724,
        name: 'E-zhe Source',
        description: 'When replacing use code 547741',
      },
      {
        id: 94725,
        name: 'Britches of Georgetown',
        description: 'Lorem Ipsum Dolor ...',
      },
    ];
  }
  getModifiers(id) {
    // return $http.get(`/demoget/edit/${id}`);
    return [
      {
        id: 74723,
        code: ['1820', '2958'],
        description: 'This EHR was set up in January still active, contact person Robert',
        writeOff: 'yes',
      },
      {
        id: 74724,
        code: ['5489', '3379', '4448'],
        description: 'When replacing use code 547741',
        writeOff: 'yes',
      },
      {
        id: 74725,
        code: ['9674'],
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        writeOff: 'no',
      },
      {
        id: 74726,
        code: ['8557', '6447'],
        description: 'Nulla venenatis dolor augue, tempor bibendum turpis auctor vitae.',
        writeOff: 'yes',
      },
    ];
  }
  getDiagnosis(id) {
    // return $http.get(`/demoget/edit/${id}`);
    return [
      {
        id: 74723,
        code: ['9874'],
        description: 'This EHR was set up in January still active, contact person Robert',
      },
      {
        id: 74724,
        code: ['1132', '3379'],
        description: 'When replacing use code 547741',
      },
      {
        id: 74725,
        code: ['9674'],
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      },
      {
        id: 74726,
        code: ['8557', '6447', '4987'],
        description: 'Nulla venenatis dolor augue, tempor bibendum turpis auctor vitae.',
      },
      {
        id: 74727,
        code: ['8557', '6447'],
        description: 'Dolor tempor dolor venenatis, vitae bibendum turpis.',
      },
    ];
  }
  getContactRoleTypes(id) {
    // return $http.get(`/demoget/edit/${id}`);
    return [
      {
        id: 74723,
        role: 'Role XYZ',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      },
      {
        id: 74724,
        role: 'Role ABC',
        description: 'Nulla venenatis dolor augue, tempor bibendum turpis auctor vitae.',
      },
      {
        id: 74725,
        role: 'Role TER',
        description: 'Dolor tempor dolor venenatis, vitae bibendum turpis.',
      },
    ];
  }
  getPOScodes(id) {
    // return $http.get(`/demoget/edit/${id}`);
    return [
      {
        id: 74723,
        code: '3002',
        description: 'This EHR was set up in January still active, contact person Robert',
        isHospital: 'no',
      },
      {
        id: 74724,
        code: '2877',
        description: 'When replacing use code 547741',
        isHospital: 'yes',
      },
      {
        id: 74725,
        code: '9874',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        isHospital: 'yes',
      },
      {
        id: 74726,
        code: '1688',
        description: 'Nulla venenatis dolor augue, tempor bibendum turpis auctor vitae.',
        isHospital: 'no',
      },
    ];
  }
  getDrSpecialties(id) {
    // return $http.get(`/demoget/edit/${id}`);
    return [
      {
        id: 74723,
        specialty: 'Emergency medicine',
        description: 'This EHR was set up in January still active, contact person Robert',
      },
      {
        id: 74724,
        specialty: 'Internal medicine',
        description: 'When replacing use code 547741',
      },
    ];
  }
  getInsurancePlan(id) {
    // return $http.get(`/demoget/edit/${id}`);
    return [
      {
        id: 74723,
        type: 'Medicate',
        description: 'Donec dictum tristique porta. Etiam convallis lorem lobortis nulla molestie.',
      },
      {
        id: 74724,
        type: 'Dental',
        description: 'Dolor tempor dolor venenatis, vitae bibendum turpis.',
      },
      {
        id: 74725,
        type: 'Health',
        description: 'Nulla venenatis dolor augue, tempor bibendum turpis auctor vitae.',
      },
      {
        id: 74726,
        type: 'Workers Comp',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      },
    ];
  }
  getUserSettings(id) {
    // return $http.get(`/demoget/edit/${id}`);
    return [
      {
        id: 65421374,
        name: 'Dr. James Hall',
        type: 'admin',
        email: 'DanielRHamilton@zoho.com',
        status: 'active',
      },
      {
        id: 65421398,
        name: 'Dr. Kenneth Allen',
        type: 'biller',
        email: 'KathyJPacheco@outlook.com',
        status: 'inactive',
      },
      {
        id: 65421132,
        name: 'Dr. Eddie Lake',
        type: 'contentManager',
        email: 'PatriciaTSanders@gmail.com',
        status: 'active',
      },
      {
        id: 65421135,
        name: 'Dr. Kurt Bates',
        type: 'superAdmin',
        email: 'RodgerJStruck@mail.com',
        status: 'active',
      },
      {
        id: 65421960,
        name: 'Dr. Brew Worswoth',
        type: 'admin',
        email: 'BWors@outlook.com',
        status: 'inactive',
      },
    ];
  }
  getPracticeList(id) {
    // return $http.get(`/demoget/edit/${id}`);
    return [
      {
        id: 39872,
        practiceName: 'Molina Healthcare',
        role: 'Supervisor',
        insCompany: [
          'Independence Health Group',
          'Guidewell Mutual Holding',
          'Local Initiative Health Authority',
          'Abc 1 ...',
          'Abc 2 ...'
        ],
      },
      {
        id: 90935,
        practiceName: 'Blue Cross Blue Shield',
        role: 'Client',
        insCompany: [
          'Highmark Group',
          'Local Initiative Health Authority',
          'Abc 1 ...',
          'Abc 2 ...'

        ],
      },
      {
        id: 15276,
        practiceName: 'Health Care Service Corporation',
        role: 'Supervisor',
        insCompany: [
          'Centene Corporation'
        ],
      },
      {
        id: 95044,
        practiceName: 'Metropolitan',
        role: 'Client Staff',
        insCompany: [
          'Caresource',
          'Local Initiative Health Authority',
          'Abc 1 ...',
          'Abc 2 ...',
          'Abc 3 ...',
          'Abc 4 ...'
        ],
      },
    ];
  }
  demoPost(data) {
    return $http.post(`/demopost`, data);
  }
}

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    ColumnFilter,
    GeneralStatus,
    PracticeUsersDto,
    PracticeUsersDtoListResponseModel,
    PracticeUsersDtoModelResponse,
    SortType,
} from "./data-contracts";
import {ContentType, HttpClient, RequestParams} from "./http-client";

export class PracticeUsers<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags PracticeUsers
     * @name LoadList
     * @request GET:/PracticeUsers/Load
     */
    loadList = (
        query?: {
            UserId?: number;
            Take?: number;
            Skip?: number;
            "OrderBy.Field"?: string;
            "OrderBy.Type"?: SortType;
            SearchTerm?: string;
            ColumnFilters?: ColumnFilter[];
            Status?: GeneralStatus;
            PracticeId?: number;
        },
        params: RequestParams = {},
    ) =>
        this.request<PracticeUsersDtoListResponseModel, any>({
            path: `/PracticeUsers/Load`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags PracticeUsers
     * @name PostPracticeUsers
     * @request POST:/PracticeUsers/Add
     */
    postPost = (data: PracticeUsersDto, params: RequestParams = {}) => this.postPracticeUsers(data, params)
    /**
     * No description
     *
     * @tags PracticeUsers
     * @name PostPracticeUsers
     * @request POST:/PracticeUsers/Add
     */
    postPracticeUsers = (data: PracticeUsersDto, params: RequestParams = {}) =>
        this.request<PracticeUsersDtoModelResponse, any>({
            path: `/PracticeUsers/Add`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags PracticeUsers
     * @name GetByIdList
     * @request GET:/PracticeUsers/GetById
     */
    getByIdList = (query?: { id?: number }, params: RequestParams = {}) =>
        this.request<PracticeUsersDtoModelResponse, any>({
            path: `/PracticeUsers/GetById`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });

    /**
     * No description
     *
     * @tags PracticeUsers
     * @name GetPracticeManager
     * @request GET:/PracticeUsers/GetPracticeManager
     */
    getPracticeManager = (query?: { practiceid?: number }, params: RequestParams = {}) =>
        this.request<PracticeUsersDtoModelResponse, any>({
            path: `/PracticeUsers/GetPracticeManager`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags PracticeUsers
     * @name AddUpdatePracticeManager
     * @request POST:/PracticeUsers/AddUpdatePracticeManager
     */
    AddUpdatePracticeManager = (data: { practiceId: number, userId: number }, params: RequestParams = {}) =>
        this.request<PracticeUsersDtoModelResponse, any>({
            path: `/PracticeUsers/AddUpdatePracticeManager`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags PracticeUsers
     * @name DeleteDelete
     * @request DELETE:/PracticeUsers/Delete
     */
    deleteDelete = (query?: { id?: number }, params: RequestParams = {}) =>
        this.request<PracticeUsersDtoModelResponse, any>({
            path: `/PracticeUsers/Delete`,
            method: "DELETE",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags PracticeUsers
     * @name UpdateCreate
     * @request POST:/PracticeUsers/Update
     */
    updateCreate = (data: PracticeUsersDto, params: RequestParams = {}) =>
        this.request<PracticeUsersDtoModelResponse, any>({
            path: `/PracticeUsers/Update`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });


}
